<template>
	<v-col cols="12">
		<div class="text-h4 mb-4 mt-6">
			Order summary
		</div>
		<v-data-table
			:headers="headers"
			:items="products"
			item-key="name"
			class="text-body-1"
			:items-per-page="-1"
			hide-default-footer
			mobile-breakpoint="0"
		>
			<template v-slot:item="{item, headers}">
				<tr v-if="item.type==='category'">
					<td
						:colspan="headers.length"
						:class="`id-${item.id}`"
					>
						<div class="text-h6" v-html="item.name"/>
					</td>
				</tr>
				<tr v-if="item.type==='product'">
					<td class="font-weight-500">
						{{ item.name }}
					</td>
					<td class="font-weight-500 text-no-wrap text-right">
						{{ item.retail_price / 100 | euro }}
					</td>
					<td class="text-right">
						{{ item.client_price / 100 | euro }}
					</td>
					<td class="text-right">
						{{ item.quantity }}
					</td>
					<td class="text-right font-weight-700 text-no-wrap text-right">
						{{ item.client_price * item.quantity / 100 | euro }}
					</td>
				</tr>
			</template>
		</v-data-table>

		<v-divider/>

		<div class="text-h5 black--text font-weight-00 mt-5">
			<div class="text-right">
				Retail value of your order: {{ order.total_payment_retail / 100 | euro }}
			</div>
			<div class="text-right mt-1">
				Wholesale value of your order: {{ order.total_payment_client / 100 | euro }}
				<span class="red--text">*</span>
			</div>
		</div>


		<div class="mt-2 pa-2 red--text font-weight-400 text-justify">
			<div class="mb-2">
				*
				The value of the order does not include any additional fees that may apply.
			</div>
			<div class="">
				*
				The cost of transport will be added to the wholesale value of your order.
			</div>
		</div>
	</v-col>
</template>
<script>

export default {
	name: 'BlockProducts',
	components: {},
	computed: {
		order() {
			return this.$store.getters['BASKET_STORE/CONFIRMATION'];
		},
		products() {
			return this.order.products;
		},

	},
	data: () => ({

		headers: [
			{
				text: 'Product', value: 'name', sortable: false, align: 'left'
			},
			{
				text: 'Retail Price (23% tax incl.)', value: 'retail', sortable: false, width: 130, align: 'right'
			},
			{
				text: 'Your Price (net)', value: 'your', sortable: false, width: 150, align: 'right',
			},
			{
				text: 'Quantity', value: 'quantity', sortable: false, width: 140, align: 'right',
			},
			{
				text: 'Total', value: 'total', sortable: false, width: 120, align: 'right',
			},
		],
	}),
	methods: {},
	created() {
	}
};
</script>
