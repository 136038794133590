<template>
	<div class="confirmation" v-if="confirmation">
		<v-row>
			<v-col cols="12">
				<div class="basket--order-summary">
					<v-card class="pa-4" outlined>
						<v-row>
							<v-col cols="12">
								<div class="text-h4 mb-0">
									Thank you for placing the order.
								</div>
								<div class="text-h6 mb-4">
									We are verifying it for you and will send more information and a proforma invoice as
									soon as possible directly to your email
									<span class="blue--text">{{ confirmation.client.email }}</span>.
								</div>
								<div>For some delivery locations (outside of the EU), verification may take slightly
									longer due to the transport costs calculation process.
								</div>
								<div>Once you receive your pro-forma invoice, the order can be paid via bank transfer.
								</div>
							</v-col>


							<block-products/>


							<v-col cols="12" v-if="confirmation.note_client">
								<v-card class="" outlined>
									<v-card-title class="pb-0">
										<div class="text-h5 font-weight-500">Additional information</div>
									</v-card-title>
									<v-card-title class="font-weight-regular text-body-1 black--text">
										<div v-html="confirmation.note_client"></div>
									</v-card-title>
								</v-card>
							</v-col>

							<v-col cols="12">
								<v-card class="" outlined>
									<v-card-title class="pb-0">
										<div class="text-h5 font-weight-500">Billing details</div>
									</v-card-title>
									<v-card-text class="mt-2 black--text text-body-1">
										<span class="">{{ confirmation.client.name }}</span><br>
										<span class="">{{ confirmation.client.billing_detail.street }}</span><br>
										<span class=" mr-1">{{ confirmation.client.billing_detail.postal_code }}</span>
										<span class="">{{ confirmation.client.billing_detail.city }}</span><br>
										<span class="">{{ confirmation.client.billing_detail.country.name }}</span>
										<span v-if="confirmation.client.billing_detail.state" class="">,
											{{ confirmation.client.billing_detail.state.name }}
										</span>
										<br>
										<span v-if="confirmation.client.billing_detail.vat_ue" class="black--text">
											VAT UE: {{ confirmation.client.billing_detail.vat_ue }}
										</span>
										<span v-if="confirmation.client.billing_detail.vat_uk" class="black--text">
											VAT UK: {{ confirmation.client.billing_detail.vat_uk }}
										</span>
										<span
											v-if="confirmation.client.billing_detail.company_data"
											class="black--text"
										>
											Company data: {{ confirmation.client.billing_detail.company_data }}
										</span>
									</v-card-text>
								</v-card>
							</v-col>
							<v-col cols="12">
								<v-card class="" outlined>
									<v-card-title class="pb-0">
										<div class="text-h5 font-weight-500">Delivery address</div>
									</v-card-title>
									<v-card-text class="mt-2 black--text text-body-1">
										<span class="">{{ confirmation.address.name }}</span><br>
										<span class="">{{ confirmation.address.street }}</span><br>
										<span class=" mr-1">{{ confirmation.address.postal_code }}</span>
										<span class="">{{ confirmation.address.city }}</span><br>
										<span class="">{{ confirmation.address.country.name }}</span>
										<span v-if="confirmation.address.state" class="">,
											{{ confirmation.address.state.name }}
										</span>
									</v-card-text>
								</v-card>
							</v-col>
							<v-col cols="12">
								<v-card class="" outlined>
									<v-card-title class="pb-0">
										<div class="text-h5 font-weight-500">Contact details</div>
									</v-card-title>
									<v-card-text class="mt-2 black--text text-body-1">
										<span class="">{{ confirmation.contact_detail.name }}</span><br>
										<span class="">Email: {{ confirmation.contact_detail.email }}</span><br>
										<span class=" mr-1">Phone: {{ confirmation.contact_detail.phone }}</span>
									</v-card-text>
								</v-card>
							</v-col>


							<v-col col="12">
								<div class="mt-0">
									<v-btn
										class="mr-2 mb-2 white--text"
										color="green"
										:to="{name: 'Dashboard'}"
										id="confirmation-continue_shopping"
									>
										Continue shopping
									</v-btn>
								</div>
							</v-col>
						</v-row>
					</v-card>

				</div>
			</v-col>
		</v-row>

	</div>
</template>
<script>

import BlockProducts from "@/views/Confirmation/Block/BlockProducts";

export default {
	name: 'ConfirmationIndex',
	components: {BlockProducts},
	computed: {
		confirmation() {
			return this.$store.getters['BASKET_STORE/CONFIRMATION'];
		},
	},
	data: () => ({}),
	methods: {},
	watch: {
		confirmation: function () {
			if (!this.confirmation) {
				this.$router.push({name: 'OrderHistoryIndex'})
			}
		}
	},
	created() {
		if (!this.confirmation) {
			this.$router.push({name: 'OrderHistoryIndex'})
		}
	},
	destroyed() {
		//this.$store.commit('BASKET_STORE/CONFIRMATION_SET', null)
	},
};
</script>
